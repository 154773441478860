import React from "react"
import Layout from "../components/layout"

import Aniversario from "../images/hommerfitness-aniversario.png"

export default () => (
  <Layout title="Eventos">
    <div className="row">
      <div className="events-wrapper">
        <h1 className="center background-title">Eventos</h1>
      </div>
    </div>

    <div className="global-inner">
      <div className="row">
        <div className="event-block col s12">
          <h3 className="date">02/2026</h3>

          <div className="text">
            <p>12 de Fevereiro Aniversário HommerFitness</p>
            <p>
              Confira a programação especial de aulas desse mês na recepção.
            </p>
            <div style={{ textAlign: "center" }}>
              <img
                className="responsive-img"
                src={Aniversario}
                alt="Fevereiro Aniversário HommerFitness"
                height="700"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)
